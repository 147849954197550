<!-- 服务订单 -->

<template>
  <div id="morders">
    <button-router></button-router>

    <router-view v-slot="{ Component }">
      <!-- <keep-alive> -->
        <component :is="Component"></component>
      <!-- </keep-alive> -->
    </router-view>
  </div>
</template>

<style lang="scss" scoped>
#morders {
  height: 100%;
}
</style>
